.App {
  width: 100vw;
  height: 100vh;
  padding: 50px;
  box-sizing: border-box;
}


@media (max-width: 600px) {
  .App {
    padding: 30px;
  }
}