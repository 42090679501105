/* LogIn Page */
.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: #f0f2f5;
}

.login-card {
    width: 90%;
    max-width: 500px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
}

.login-form {
    width: 100%;
    text-align: right;
}

.login-submit-button {
    width: 100%;
}

/* Main Page */
.main-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 20px;
}

.main-card {
    width: 300px;
    margin-block: 20px;
    margin-inline: 20px;
    padding: 10px;
    text-align: center;
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.main-icon {
    font-size: 50px;
    margin: 20px 0;
}

.card-description {}

/* New Invoice Page */

.new-invoice-main {
    padding: 40px;
}

/* Prev Invoice Page */
.prev-invoices-container {
    padding: 20px;
}

.ant-table-thead>tr>th {
    background-color: #fafafa;
    font-weight: bold;
}

.ant-table-tbody>tr>td {
    vertical-align: middle;
}

.ant-btn {
    margin-right: 5px;
}

.actions-column {
    display: flex;
    justify-content: flex-end;
    gap: 5px;
}

.actions-cell {
    text-align: right !important;
}

/* Analytics Page */
.analytics-page {
    padding: 40px;
}

.analytic-quick-div {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.quick-statistic {
    width: 200px;
    text-align: center;
}

.graph-container {
    padding: 20px;
}

/* Download Invoice Page */
.bill-outer-div {
    width: auto;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
}

.bill {
    width: 210mm;
    /* height: 148.5mm; */

    padding: 1%;
    box-sizing: border-box;
    /* background-color: aliceblue; */
}

.bill-border {
    width: 100%;
    height: 100%;
    border: 1px solid black;

    display: flex;
    flex-direction: column;
}

.bill-inner-div1 {
    /* height: 43mm; */
    /* background-color: aqua; */
}

.bill-top-div {
    display: flex;
    /* background-color: thistle; */
}

.bill-top1 {
    flex: 1;
    /* background-color: yellowgreen; */
}

.bill-top2 {
    flex: 1;
    /* background-color: violet; */
    display: flex;
    flex-direction: column;
    font-size: 8px;
    justify-content: center;
    align-items: center;
}

.bill-top3 {
    flex: 1;
    /* background-color: tomato; */
    display: flex;
    font-size: 12px;
    justify-content: end;
    padding: 1%;
    box-sizing: border-box;
    align-items: center;
}

.bill-party-gstin {
    /* background-color: tomato; */
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 1%;
    box-sizing: border-box;
}

.party {
    font-size: 12px;
    width: 60%;
    /* background-color: yellow; */
}

.party-address {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.gstin-pan {
    font-size: 12px;
    font-weight: bolder;
    font-style: italic;
    inline-size: none;
}

.due-date {
    font-size: 12px;
}

.bill-inner-div2 {
    /* height: 60mm; */
    padding: 1%;
    box-sizing: border-box;
    /* background-color: aquamarine; */
}

.table {
    width: 100%;
    border-collapse: collapse;

    font-size: 12px;
    text-align: center;
}

thead {
    background-color: lightgray;
}

.total-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.material-details {
    height: 55%;
    /* background-color: yellowgreen; */
}

.lrcharges-div {
    height: 25%;
    font-size: 12px;
    /* background-color: aquamarine; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: end;
}

.all-lr-charges {
    width: 20%;
    display: flex;
    justify-content: space-between;
}

.sub-total {
    width: 20%;
    border-top: 1px solid black;
    display: flex;
    justify-content: space-between;
}

.total-div {
    /* height: 10%; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* background-color: violet; */
    padding: 1%;
    box-sizing: border-box;
    border: 1px solid black;
}

.bill-inner-div3 {
    /* height: 30mm; */
    /* background-color: bisque; */

    border-top: 1px solid black;
    padding: 1%;
    box-sizing: border-box;
}

.tnc {
    font-size: 8px;
    width: 60%;
    /* background-color: burlywood; */
}

.tnc-qr-div {
    height: 50%;
    display: flex;
    justify-content: space-between;
}

.bill-botton-sign-div {
    width: 100%;
    height: 50%;
    /* background-color: azure; */
    font-size: 11px;

    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sign4,
.sign3,
.sign2,
.sign1 {
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.sign4 {
    text-align: right;
}

@media (max-width: 600px) {
    .quick-statistic {
        width: auto;
        text-align: center;
    }

    .analytic-quick-div {
        flex-direction: column;
        gap: 10px;
    }

    .graph-container {
        padding: 0px;
    }

    .prev-table-div {
        width: 100%;
        box-sizing: border-box;
        overflow: auto;
    }
}