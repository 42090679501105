.steps-content-div {
    line-height: 260px;
    text-align: center;
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.form {
    width: 100%;
    max-width: 600px;
    text-align: left;
}

.material-form {
    width: 100%;
    max-width: 650px;
    text-align: left;
}

.form-two-input-div {
    display: flex;
    justify-content: space-between;
}

.form-two-input-items {
    width: 48%;
}

/* NavBar */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-inline: 50px;
    background-color: lightpink;
}

.toggleButton {}

/* Generate Bill */

@media (max-width: 600px) {
    .form-two-input-div {
        flex-direction: column;
    }

    .form-two-input-items {
        width: 100%;
    }
}